import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { AnchorTarget } from "@tyson-foods/react-component-library"
import { PrivacyPolicy } from "@tyson-foods/react-component-library"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../scss/privacy-policy.scss"

const SecondPage = () => (
  <Layout
    metaTitle="Privacy Policy"
    metaDescription="This Privacy Policy describes how we treat personal information on the websites, apps and platforms where it is located."
    metaKeywords=""
  >
    <PrivacyPolicy
      className="p-5"
      showTitle
      aboutOurAdsLink={<Link to="/about-our-ads">HERE</Link>}
    />
  </Layout>
)

export default SecondPage
